import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { setRegistrationSuccess } from "../../store/features/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config.json";

export const RegistrationSuccess = () => {
  const { showRegistrationSuccess } = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setRegistrationSuccess(false));
  };
  return (
    <React.Fragment>
      <Dialog open={showRegistrationSuccess} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Registration Successful"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Thank you for registering to the {config?.appInfo?.appName}. A request has been sent to approve your registration, which is required
            before you can sign in. You will be receiving an email when the approval has been completed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Dismiss</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
