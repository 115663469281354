import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import axios from "../lib/axios.js";
import RHFMultiSelect from "./RHFMultiSelect.jsx";
import { useDispatch, useSelector } from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grow from "@mui/material/Grow";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatPhone } from "../lib/misc.js";
import { setFacilitySelected, setSameAsReporter } from "../store/features/conditionSlice.js";
import config from "../../../config.json";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function OrderingInformation(props) {
  const [clinicianDefaulted, setClinicianDefaulted] = React.useState(false);
  const { currentUser } = useSelector((state) => state.session);
  const { specimen_sources, counties, states, testing_reasons } = useSelector((state) => state.caseDropdown.caseDropdown);
  const [filteredCounties, setFilteredCounties] = React.useState([]);
  const [facilities, setFacilities] = React.useState([]);
  const { noLabChecked, sameAsReporter, facilitySelected } = useSelector((state) => state.condition);
  const dispatch = useDispatch();
  const [stateChanged, setStateChanged] = React.useState(false);

  const handleToggleSameAsReporter = (event) => {
    if (props?.readonly) return;
    dispatch(setSameAsReporter(event.target.checked));
    const values = props.getValues();
    if (event.target.checked) {
      const facility = currentUser.facilities.find((facility) => facility.id === values.facility_id.facility_id);
      handleFacilitySelection(null, facility);

      console.debug(values);

      props.setValue("order.clinician_first_name", values.clinician.first_name);
      props.setValue("order.clinician_last_name", values.clinician.last_name);
    } else {
      props.setValue("order.clinician_first_name", null);
      props.setValue("order.clinician_last_name", null);
      handleFacilitySelection(null, null);
    }
  };

  // Fetching and initalizing the facilities, states, and counties used in autocomplete components
  useEffect(() => {
    axios
      .get("/api/facilities/")
      .then((resp) => {
        setFacilities(resp.data.facilities);
      })
      .catch((e) => {
        console.log("Error fetching option sources from /facilities: ", e);
      }); // TODO: Better error handling
  }, [setFacilities]);

  useEffect(() => {
    if (!props.caseId && !stateChanged) {
      handleStateChange(
        null,
        states?.find((s) => s.code === process.env.DEFAULT_STATE)
      );
    }
  }, [props]);

  useEffect(() => {
    if (!props.caseId) {
      dispatch(setSameAsReporter(false));
    }
  }, [props.caseId]);

  const handleStateChange = (e, v) => {
    if (v?.id) {
      setStateChanged(true);
    }
    props.setValue("order.facility.address.state_id", v);
    const c = counties.filter((county) => county.state_id === v.id);
    setFilteredCounties(c);
  };

  const handleNewFacility = (e) => {
    props.setValue("order.facility.name", e.target.value);
  };

  const handleFacilitySelection = (e, v) => {
    dispatch(setFacilitySelected(false));
    console.log(v);
    if (v === null) {
      props.setValue("order.facility_id", null);
      props.setValue("order.facility.name", null);
      props.setValue("order.facility.address.street", null);
      props.setValue("order.facility.address.unit", null);
      props.setValue("order.facility.address.city", null);
      props.setValue("order.facility.address.zip", null);
      props.setValue("order.facility.address.state_id", null);
      props.setValue("order.facility.address.county_id", null);
      props.setValue("order.facility.area_code", null);
      props.setValue("order.facility.phone_number", null);
      props.setValue("order.facility.phone", null);

      return;
    }

    const reportingFacility = facilities.find((facility) => facility.id === v.id);
    if (reportingFacility) {
      dispatch(setFacilitySelected(true));
      props.setValue("order.facility_id", reportingFacility, {
        shouldValidate: true,
      });
      props.setValue("order.facility.name", reportingFacility.name, {
        shouldValidate: true,
      });
      props.setValue("order.facility.address.street", reportingFacility.full_address.street, { shouldValidate: true });
      props.setValue("order.facility.address.unit", reportingFacility.full_address.unit, { shouldValidate: true });
      props.setValue("order.facility.address.city", reportingFacility.full_address.city, { shouldValidate: true });
      props.setValue("order.facility.address.zip", reportingFacility.full_address.zip, { shouldValidate: true });
      props.setValue(
        "order.facility.address.state_id",
        states.find((state) => state.id === reportingFacility.full_address.state_id),
        { shouldValidate: true }
      );
      props.setValue(
        "order.facility.address.county_id",
        counties.find((county) => county.id === reportingFacility.full_address.county_id),
        { shouldValidate: true }
      );
      props.setValue("order.facility.area_code", reportingFacility.area_code, {
        shouldValidate: true,
      });
      props.setValue("order.facility.phone_number", reportingFacility.phone_number, { shouldValidate: true });
      props.setValue("order.facility.phone", formatPhone(reportingFacility.area_code, reportingFacility.phone_number) || "", {
        shouldValidate: true,
      });
    }
  };

  const orderingFacilityPhone =
    props.caseId || sameAsReporter || facilitySelected ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="order.facility.phone"
            fullWidth={true}
            inputProps={{ readOnly: props.readonly || sameAsReporter || facilitySelected }}
            InputLabelProps={{ shrink: true }}
            variant="standard"
            disabled={sameAsReporter}
            label="Phone Number"
            data-testid="order.facility.phone"
            {...props.register("order.facility.phone")}
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container>
        <Grid item xs={3}>
          <TextField
            name="order.facility.area_code"
            inputProps={{ readOnly: props.readonly || sameAsReporter || facilitySelected }}
            label="Area Code"
            variant="standard"
            error={!!props.errors.order?.facility?.area_code}
            helperText={props.errors.order?.facility?.area_code && props.errors.order?.facility?.area_code.message}
            {...props.register("order.facility.area_code")}
            InputLabelProps={{
              shrink: props.watch("order.facility.area_code") !== "",
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            name="order.facility.phone_number"
            inputProps={{ readOnly: props.readonly || sameAsReporter || facilitySelected }}
            label="Phone Number"
            variant="standard"
            error={!!props.errors.order?.facility?.phone_number}
            helperText={props.errors.order?.facility?.phone_number && props.errors.order?.facility?.phone_number.message}
            {...props.register("order.facility.phone_number")}
            InputLabelProps={{
              shrink: props.watch("order.facility.phone_number") !== "",
            }}
          />
        </Grid>
      </Grid>
    );

  return (
    <div>
      {config?.order?.testingReason && (
        <Grid className="pb-3" xs={6} container>
          <RHFMultiSelect
            fullWidth
            name="order.testing_reason_id"
            register={props.register}
            readOnly={props.readonly}
            control={props.control}
            label="Reason Test was Ordered"
            options={testing_reasons}
            error={!!props.errors.order?.testing_reason_id}
            helperText={props.errors.order?.testing_reason_id && props.errors.order?.testing_reason_id.message}
            objGOS={true}
          />
        </Grid>
      )}
      <Grid className="pb-3" container spacing={2}>
        {isNaN(props.caseId) && (
          <Grid item xs={12}>
            <FormControlLabel
              value="start"
              control={
                <Switch
                  disabled={props.readonly}
                  checked={sameAsReporter}
                  onChange={handleToggleSameAsReporter}
                  inputProps={{
                    "aria-label": "controlled",
                    disabled: false,
                  }}
                />
              }
              label={<Typography variant="subtitle2">Same as reporter</Typography>}
              labelPlacement="end"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography variant="subtitle2">Facility</Typography>
          <div>
            <RHFMultiSelect
              disabled={sameAsReporter}
              name="order.facility_id"
              fullWidth
              register={props.register}
              readOnly={props.readonly}
              addBlankOption={true}
              control={props.control}
              label="Facility *"
              options={facilities}
              freeSolo={true}
              getOptionLabel={(o) => o?.name || ""}
              getOptionSelected={(o) => o?.name || ""}
              onChange={handleFacilitySelection}
              onTextChange={handleNewFacility}
              error={!!props.errors.order?.facility_id}
              helperText={props.errors.order?.facility_id && props.errors.order?.facility_id.message}
              objGOS={true}
              inputProps={{ "data-testid": "order.facility_id" }}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">Clinician</Typography>
          <div>
            <TextField
              className="w-100"
              name="order.clinician_last_name"
              inputProps={{ readOnly: props.readonly }}
              label="Clinician Last Name"
              variant="standard"
              InputLabelProps={{
                shrink: props.watch("order.clinician.last_name") !== "",
              }}
              {...props.register("order.clinician_last_name")}
              error={!!props.errors.order?.clinician_last_name}
              helperText={props.errors.order?.clinician_last_name && props.errors.order?.clinician_last_name.message}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">&nbsp;</Typography>
          <div>
            <TextField
              className="w-100"
              name="order.clinician_first_name"
              inputProps={{ readOnly: props.readonly }}
              label="Clinician First Name"
              InputLabelProps={{
                shrink: props.watch("order.clinician.first_name") !== "",
              }}
              variant="standard"
              {...props.register("order.clinician_first_name")}
              error={!!props.errors.order?.clinician_first_name}
              helperText={props.errors.order?.clinician_first_name && props.errors.order?.clinician_first_name.message}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <TextField
            disabled={props.readonly || sameAsReporter || facilitySelected}
            InputProps={{ readOnly: props.readonly }}
            InputLabelProps={{
              shrink: props.watch("order.facility.address.street") !== "",
            }}
            label="Street *"
            variant="standard"
            className="w-100"
            {...props.register("order.facility.address.street")}
            error={!!props.errors.order?.facility?.address?.street}
            helperText={props.errors.order?.facility?.address?.street && props.errors.order?.facility?.address?.street.message}
            inputProps={{ "data-testid": "order.facility.address.street" }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            disabled={props.readonly || sameAsReporter || facilitySelected}
            InputProps={{ readOnly: props.readonly }}
            label="Unit"
            variant="standard"
            className="w-100"
            {...props.register("order.facility.address.unit")}
            InputLabelProps={{
              shrink: props.watch("order.facility.address.unit") !== "",
            }}
            error={!!props.errors.order?.facility?.address?.unit}
            helperText={props.errors.order?.facility?.address?.unit && props.errors.order?.facility?.address?.unit.message}
            inputProps={{ "data-testid": "order.facility.address.unit" }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            disabled={props.readonly || sameAsReporter || facilitySelected}
            InputProps={{ readOnly: props.readonly }}
            label="City *"
            variant="standard"
            className="w-100"
            {...props.register("order.facility.address.city")}
            InputLabelProps={{
              shrink: props.watch("order.facility.address.city") !== "",
            }}
            error={!!props.errors.order?.facility?.address?.city}
            helperText={props.errors.order?.facility?.address?.city && props.errors.order?.facility?.address?.city.message}
            inputProps={{ "data-testid": "order.facility.address.city" }}
          />
        </Grid>
        <Grid item xs={1}>
          <RHFMultiSelect
            name="order.facility.address.state_id"
            fullWidth
            register={props.register}
            disabled={props.readonly || sameAsReporter || facilitySelected}
            readOnly={props.readonly}
            control={props.control}
            label="State *"
            options={states}
            onChange={handleStateChange}
            getOptionLabel={(o) => o?.code || ""}
            getOptionSelected={(o) => o?.code || ""}
            error={!!props.errors.order?.facility?.address?.state_id}
            helperText={props.errors.order?.facility?.address?.state_id && props.errors.order?.facility?.address?.state_id.message}
            objGOS={true}
            inputProps={{
              "data-testid": "order.facility.address.state_id",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <RHFMultiSelect
            name="order.facility.address.county_id"
            fullWidth
            disabled={props.readonly || sameAsReporter || facilitySelected}
            readOnly={props.readonly}
            register={props.register}
            label="County *"
            getOptionLabel={(o) => o?.name || ""}
            getOptionSelected={(o) => o?.name || ""}
            control={props.control}
            options={filteredCounties}
            variant="standard"
            error={!!props.errors.order?.facility?.address?.county_id}
            helperText={props.errors.order?.facility?.address?.county_id && props.errors.order?.facility?.address?.county_id.message}
            objGOS={true}
            inputProps={{
              "data-testid": "order.facility.address.county_id",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Zip *"
            variant="standard"
            className="w-100"
            disabled={props.readonly || sameAsReporter || facilitySelected}
            InputProps={{ readOnly: props.readonly }}
            {...props.register("order.facility.address.zip")}
            InputLabelProps={{
              shrink: props.watch("order.facility.address.zip") !== "",
            }}
            error={!!props.errors.order?.facility?.address?.zip}
            helperText={props.errors.order?.facility?.address?.zip && props.errors.order?.facility?.address?.zip.message}
            inputProps={{ "data-testid": "order.facility.address.zip" }}
          />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {orderingFacilityPhone}
      </Grid>
    </div>
  );
}
OrderingInformation.propTypes = {
  errors: PropTypes.shape({
    clinician: PropTypes.shape({
      area_code: PropTypes.object,
      phone_number: PropTypes.object,
    }),
  }),
  caseId: PropTypes.string,
  setValue: PropTypes.func,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentUser: PropTypes.object,
  clinLastName: PropTypes.bool,
  setClinLastName: PropTypes.func,
};
