import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conditionTreatments: [],
  testTypes: [],
  conditionSpecimens: [],
  conditionSymptoms: [],
  treatment: false,
  notifiable: false,
  notifiableDialogOpen: false,
  treatmentAllowed: false,
  labReportRequired: false,
  noLabChecked: false,
  labExpanded: true,
  treatmentCount: 1,
  testResultCount: 1,
  symptomsEnabled: false,
  symptomCount: 1,
  // Ordering Information
  sameAsReporter: false,
  facilitySelected: false,
};

const conditionSlice = createSlice({
  name: "condition",
  initialState,
  reducers: {
    setTreatments(state, action) {
      state.conditionTreatments = action.payload;
    },
    setTestTypes(state, action) {
      state.testTypes = action.payload;
    },
    setConditionSpecimens(state, action) {
      state.conditionSpecimens = action.payload;
    },
    setTreatment(state, action) {
      console.log("setting treatment", action.payload);
      state.treatment = action.payload;
    },
    setNotifiable(state, action) {
      state.notifiable = action.payload;
      state.notifiableDialogOpen = action.payload;
    },
    setNotifiableDialogOpen(state, action) {
      state.notifiableDialogOpen = action.payload;
    },
    setTreatmentAllowed(state, action) {
      state.treatmentAllowed = action.payload;
    },
    setTreatmentCount(state, action) {
      state.treatmentCount = action.payload;
    },
    setTestResultCount(state, action) {
      state.testResultCount = action.payload;
    },
    setLabReportRequired(state, action) {
      state.labReportRequired = action.payload;
    },
    setNoLabChecked(state, action) {
      state.noLabChecked = action.payload;
    },
    setLabExpanded(state, action) {
      state.labExpanded = action.payload;
    },
    setSameAsReporter(state, action) {
      state.sameAsReporter = action.payload;
    },
    setFacilitySelected(state, action) {
      state.facilitySelected = action.payload;
    },
    setSymptomsEnabled(state, action) {
      state.symptomsEnabled = action.payload;
    },
    setSymptomCount(state, action) {
      state.symptomCount = action.payload;
    },
    setSymptoms(state, action) {
      state.conditionSymptoms = action.payload;
    },
  },
});

export const {
  setTreatments,
  setTestTypes,
  setTreatment,
  setNotifiable,
  setNotifiableDialogOpen,
  setTreatmentAllowed,
  setLabReportRequired,
  setNoLabChecked,
  setLabExpanded,
  setTreatmentCount,
  setTestResultCount,
  setSymptomCount,
  setSymptomsEnabled,
  setSymptoms,
  setConditionSpecimens,
  setSameAsReporter,
  setFacilitySelected,
} = conditionSlice.actions;

export default conditionSlice.reducer;
